input[type="file"]::-webkit-file-upload-button {
  background-color: #3498ff;
  border-radius: 20px
}

input[type="file"]::-webkit-file-upload-button:hover {
  background-color: #1675e0;
}

input[type="file"] {
  border: none;
  background-color: transparent;
  border-radius: 20px
}
